import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import "react-bulma-components/dist/react-bulma-components.min.css";
import {
  Button,
  Container,
  Columns,
  Form,
  Heading,
  Message,
  Section
} from "react-bulma-components";

const PasswordForgetPage = () => (
  <Section>
    <Container>
      <Columns>
        <Columns.Column size="one-third">
          <Heading>Password Forget</Heading>
          <PasswordForgetForm />
        </Columns.Column>
      </Columns>
    </Container>
  </Section>
);

const INITIAL_STATE = {
  email: "",
  error: null
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;
    const isInvalid = email === "";

    return (
      <form style={{ marginBottom: "0.75em" }} onSubmit={this.onSubmit}>
        <Form.Field kind="addons">
          <Form.Control fullwidth={true}>
            <Form.Input
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              type="text"
              placeholder="Email"
            />
          </Form.Control>

          <Form.Control>
            <Button disabled={isInvalid} type="submit">
              Send Password Reset
            </Button>
          </Form.Control>
        </Form.Field>

        {/* TODO: turn this into a component */}
        {error && (
          <Message color="danger">
            <Message.Header>Error</Message.Header>
            <Message.Body style={{ wordBreak: "break-word" }}>
              {error.message}
            </Message.Body>
          </Message>
        )}
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
