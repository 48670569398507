import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";

import "react-bulma-components/dist/react-bulma-components.min.css";
import {
  Button,
  Container,
  Columns,
  Form,
  Heading,
  Message,
  Section
} from "react-bulma-components";
import { SignInLink } from "../SignIn";

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  isAdmin: false,
  error: null
};

const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use";

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this email address already exists. Try to login with 
  this account instead. If you think the account is already used from 
  one of the social logins, try to sign-in with one of them. Afterward, 
  associate your accounts on your personal account page.`;

const SignUpPage = () => (
  <Section>
    <Container>
      <Columns>
        <Columns.Column size="one-third">
          <Heading>Sign Up</Heading>
          <SignUpForm />
          <SignInLink />
        </Columns.Column>
      </Columns>
    </Container>
  </Section>
);

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { username, email, passwordOne, isAdmin } = this.state;
    const roles = [];

    if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN;
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        this.props.firebase
          .user(authUser.user.uid)
          .set({
            username,
            email,
            roles
          })
          // TODO: add this into release
          // .then(() => {
          //   return this.props.firebase.doSendEmailVerification();
          // })
          .then(() => {
            this.setState({ ...INITIAL_STATE });
            this.props.history.push(ROUTES.PROJECTS);
          })
          .catch(error => {
            if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
              error.message = ERROR_MSG_ACCOUNT_EXISTS;
            }

            this.setState({ error });
          });

        const newProjectsKey = this.props.firebase
          .projects()
          .push()
          .getKey();
        this.props.firebase
          .user(authUser.user.uid)
          .update({ projectsId: newProjectsKey });

        const newFriendsKey = this.props.firebase
          .friends()
          .push()
          .getKey();
        this.props.firebase
          .user(authUser.user.uid)
          .update({ friendsId: newFriendsKey });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const { username, email, passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      username === "";

    return (
      <form onSubmit={this.onSubmit}>
        <Form.Field>
          <Form.Label>Username</Form.Label>
          <Form.Control>
            <Form.Input
              name="username"
              value={username}
              onChange={this.onChange}
              type="text"
            />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label>Email Address</Form.Label>
          <Form.Control>
            <Form.Input
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
            />
          </Form.Control>
        </Form.Field>

        <Form.Field>
          <Form.Label>Password</Form.Label>
          <Form.Control>
            <Form.Input
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
            />
          </Form.Control>
        </Form.Field>

        <Form.Field>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control>
            <Form.Input
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
            />
          </Form.Control>
        </Form.Field>

        {/* <label>
          Admin:
          <input
            name="isAdmin"
            type="checkbox"
            checked={isAdmin}
            onChange={this.onChangeCheckbox}
          />
        </label> */}

        <Form.Field>
          <Form.Control>
            <Button
              color="primary"
              // size="medium"
              fullwidth={true}
              disabled={isInvalid}
              type="submit"
            >
              Sign Up
            </Button>
          </Form.Control>
        </Form.Field>

        {/* TODO: turn this into a component */}
        {error && (
          <Message color="danger">
            <Message.Header>Error</Message.Header>
            <Message.Body style={{ wordBreak: "break-word" }}>
              {error.message}
            </Message.Body>
          </Message>
        )}
      </form>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
