import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

import { SignUpLink } from "../SignUp";
import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import "react-bulma-components/dist/react-bulma-components.min.css";
import {
  Button,
  Container,
  Columns,
  Form,
  Heading,
  Message,
  Section
} from "react-bulma-components";

// TODO: change name to "Login"
const SignInPage = () => (
  <Section>
    <Container>
      <Columns>
        <Columns.Column size="one-third">
          <Heading>Sign In</Heading>
          <SignInForm />
          <SignInFacebook />
          <SignInGoogle />
          <PasswordForgetLink />
          <SignUpLink />
        </Columns.Column>
      </Columns>
    </Container>
  </Section>
);

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null
};

const ERROR_CODE_ACCOUNT_EXISTS =
  "auth/account-exists-with-different-credential";

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an email address to this social account already exists. 
  Try to login from this account instead and associate your social accounts 
  on your personal account page.`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.PROJECTS);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <form style={{ marginBottom: "0.75em" }} onSubmit={this.onSubmit}>
        <Form.Field>
          <Form.Label>Email Address</Form.Label>
          <Form.Control>
            <Form.Input
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
            />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Label>Password</Form.Label>
          <Form.Control>
            <Form.Input
              name="password"
              value={password}
              onChange={this.onChange}
              type="password"
            />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Control>
            <Button
              color="primary"
              fullwidth={true}
              disabled={isInvalid}
              type="submit"
            >
              Sign In
            </Button>
          </Form.Control>
        </Form.Field>

        {/* TODO: turn this into a component */}
        {error && (
          <Message color="danger">
            <Message.Header>Error</Message.Header>
            <Message.Body style={{ wordBreak: "break-word" }}>
              {error.message}
            </Message.Body>
          </Message>
        )}
      </form>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in the Firebase Realtime db
        this.props.firebase
          .user(socialAuthUser.user.uid)
          .set({
            username: socialAuthUser.user.displayName,
            email: socialAuthUser.user.email,
            roles: []
          })
          .then(() => {
            this.setState({ error: null });
            this.props.history.push(ROUTES.PROJECTS);
          })
          .catch(error => {
            if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
              error.message = ERROR_MSG_ACCOUNT_EXISTS;
            }

            this.setState({ error });
          });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form style={{ marginBottom: "0.75em" }} onSubmit={this.onSubmit}>
        {/* TODO: put logo */}
        <Button fullwidth={true} type="submit">
          Sign In with Google
        </Button>

        {/* TODO: turn this into a component */}
        {error && (
          <Message color="danger">
            <Message.Header>Error</Message.Header>
            <Message.Body style={{ wordBreak: "break-word" }}>
              {error.message}
            </Message.Body>
          </Message>
        )}
      </form>
    );
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        // Create a user in the Firebase Realtime db
        this.props.firebase
          .user(socialAuthUser.user.uid)
          .set({
            username: socialAuthUser.additionalUserInfo.profile.name,
            email: socialAuthUser.additionalUserInfo.profile.email,
            roles: []
          })
          .then(() => {
            this.setState({ error: null });
            this.props.history.push(ROUTES.PROJECTS);
          })
          .catch(error => {
            if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
              error.message = ERROR_MSG_ACCOUNT_EXISTS;
            }

            this.setState({ error });
          });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form style={{ marginBottom: "0.75em" }} onSubmit={this.onSubmit}>
        {/* TODO: put logo */}

        <Button fullwidth={true} type="submit">
          Sign In with Facebook
        </Button>

        {/* TODO: turn this into a component */}
        {error && (
          <Message color="danger">
            <Message.Header>Error</Message.Header>
            <Message.Body style={{ wordBreak: "break-word" }}>
              {error.message}
            </Message.Body>
          </Message>
        )}
      </form>
    );
  }
}

const SignInLink = () => (
  <p>
    Already have an account? <Link to={ROUTES.SIGN_IN}>Sign In</Link>
  </p>
);

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

const SignInGoogle = compose(withRouter, withFirebase)(SignInGoogleBase);

const SignInFacebook = compose(withRouter, withFirebase)(SignInFacebookBase);

export default SignInPage;

export { SignInForm, SignInGoogle, SignInFacebook, SignInLink };
