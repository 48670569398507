import React from "react";
import { compose } from "recompose";
import SignOutButton from "../SignOut";

import "react-bulma-components/dist/react-bulma-components.min.css";
import { Container, Columns, Heading, Section } from "react-bulma-components";

import {
  AuthUserContext,
  withAuthorization
  // withEmailVerification
} from "../Session";
import PasswordChangeForm from "../PasswordChange";

const AccountPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <Section>
        <Container>
          <Columns>
            <Columns.Column size="one-third">
              <Heading>Account: {authUser.email}</Heading>
              <PasswordChangeForm />
              <SignOutButton />
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    )}
  </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default compose(
  //withEmailVerification,
  withAuthorization(condition)
)(AccountPage);
