import React, { Component } from "react";
import { compose } from "recompose";

import "react-bulma-components/dist/react-bulma-components.min.css";
import {
  Button,
  Card,
  Container,
  Form,
  Level,
  Progress,
  Section
} from "react-bulma-components";

import {
  AuthUserContext,
  withAuthorization
  // withEmailVerification
} from "../Session";

import { withFirebase } from "../Firebase";

const ProjectsPage = () => (
  <Section>
    <Container>
      <Projects />
    </Container>
  </Section>
);

// TODO: create listener for newly added friend projects
class ProjectsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      isLoading: false,
      userProjects: [],
      // TODO: remove this field
      projects: [],
      friendsProjects: [],
      friendEmail: "",
      friendsAccepted: [],
      friendsPending: [],
      friendsReceived: []
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    // TODO: find a more efficient way to decay projects
    this.props.firebase
      .project(this.props.authUser.projectsId)
      // TODO: try changing "value" to "child_changed"
      .once("value", snapshot => {
        // TODO: compare projectObject and userProjects
        const projectObject = snapshot.val();

        if (projectObject) {
          // TODO: is it necessary to convert to userProjects?
          const userProjects = Object.keys(projectObject).map(key => ({
            ...projectObject[key],
            uid: key
          }));
          // TODO: move isLoading out of here to bottom of function
          // TODO: make it so state is only set once
          this.setState({ userProjects: userProjects, isLoading: false });
        } else {
          this.setState({ userProjects: null, isLoading: false });
        }

        if (
          this.state.userProjects != null &&
          this.state.userProjects.length > 0
        ) {
          // TODO: move this out of the listener
          this.state.userProjects.forEach(project => {
            this.decayProjectHealth(
              this.props.authUser.projectsId,
              project.uid
            );
          });
        }
      });

    // TODO: figure out what the below actually does
    // TODO: how does this listen if there's no value in the db?
    // TODO: why does this run twice on project update?
    this.props.firebase
      .project(this.props.authUser.projectsId)
      // TODO: try changing "value" to "child_changed"
      .on("value", snapshot => {
        // TODO: compare projectObject and userProjects
        const projectObject = snapshot.val();

        if (projectObject) {
          // TODO: is it necessary to convert to userProjects?
          const userProjects = Object.keys(projectObject).map(key => ({
            ...projectObject[key],
            uid: key
          }));
          // TODO: move isLoading out of here to bottom of function
          // TODO: make it so state is only set once
          this.setState({ userProjects: userProjects, isLoading: false });
        } else {
          this.setState({ userProjects: null, isLoading: false });
        }
      });

    // TODO: only list "accepted" friends
    this.props.firebase
      .friend(this.props.authUser.friendsId)
      .on("value", snapshot => {
        if (snapshot.hasChildren()) {
          let friendsAccepted = [];
          let friendsPending = [];
          let friendsReceived = [];

          // TODO: refactor this into a hashmap e.g
          // friends[child.val()].push(child.key)
          snapshot.forEach(child => {
            if (child.val().status === "accepted") {
              friendsAccepted.push({
                ...child.val(),
                uid: child.key
              });
            } else if (child.val().status === "pending") {
              friendsPending.push({ ...child.val(), uid: child.key });
            } else if (child.val().status === "received") {
              friendsReceived.push({ ...child.val(), uid: child.key });
            }
          });

          this.setState({
            friendsAccepted: friendsAccepted,
            friendsPending: friendsPending,
            friendsReceived: friendsReceived
          });
        } else {
          this.setState({
            friendsAccepted: null,
            friendsPending: null,
            friendsReceived: null
          });
        }
      });
  }

  componentWillUnmount() {
    // TODO: change this to match on in componentDidMount()
    // TODO: investigate what this does in Road to React with Firebase

    this.props.firebase.project(this.props.authUser.projectsId).off();
    this.props.firebase.friend(this.props.authUser.friendsId).off();
  }

  onChangeProjectName = event => {
    this.setState({ name: event.target.value });
  };

  // TODO: only get authUser.uid, not entire object
  // TODO: refactor to onAddProject - keep consisten
  onCreateProject = (event, authUser) => {
    const newProjectName = this.state.name;

    // TODO: display error if name is too long
    if (newProjectName.length <= 80) {
      this.props.firebase.project(authUser.projectsId).push({
        name: this.state.name,
        health: 100,
        userId: authUser.uid,
        createdAt: this.props.firebase.serverValue.TIMESTAMP,
        editedAt: this.props.firebase.serverValue.TIMESTAMP,
        healthUpdatedAt: this.props.firebase.serverValue.TIMESTAMP
      });

      this.setState({ name: "" });
    }

    event.preventDefault();
  };

  onRemoveProject = (projectsId, projectId) => {
    this.props.firebase
      .project(projectsId)
      .child(projectId)
      .remove();
  };

  // TODO: change to onEditProjectName
  onEditProject = (projectsId, project, name) => {
    const { uid, ...projectSnapshot } = project;

    this.props.firebase
      .project(projectsId)
      .child(project.uid)
      .set({
        ...projectSnapshot,
        name,
        editedAt: this.props.firebase.serverValue.TIMESTAMP
      });
  };

  onUpdateHealth = (projectsId, project, health, amount) => {
    const { uid, ...projectSnapshot } = project;

    // TODO: change "set" to "update"
    this.props.firebase
      .project(projectsId)
      .child(project.uid)
      .set({
        ...projectSnapshot,
        health: health + amount,
        healthUpdatedAt: this.props.firebase.serverValue.TIMESTAMP
      });
  };

  decayProjectHealth = (projectId, singleProjectId) => {
    this.props.firebase
      .project(projectId)
      .child(singleProjectId)
      .once("value", snapshot => {
        const project = snapshot.val();

        const elapsedTime = new Date().getTime() - project.healthUpdatedAt;
        const elapsedTimeInHours = elapsedTime / 3600000;

        // How many hours it takes to decay a project by one unit
        const hoursUntilDecay = 4.5;

        // If the project needs to be decayed
        if (elapsedTimeInHours > hoursUntilDecay) {
          const unitsToDecay = Math.floor(elapsedTimeInHours / hoursUntilDecay);

          let projectHealthUnits = project.health / 5;

          // If the project can be decayed
          if (projectHealthUnits > 0) {
            projectHealthUnits = projectHealthUnits - unitsToDecay;

            if (projectHealthUnits < 0) {
              projectHealthUnits = 0;
            }

            this.props.firebase
              .project(projectId)
              .child(singleProjectId)
              .update({
                health: projectHealthUnits * 5,
                healthUpdatedAt: this.props.firebase.serverValue.TIMESTAMP
              });
          }
        }
      });
  };

  onAddFriendRequest = (event, authUser, friendEmail) => {
    this.props.firebase
      .users()
      .orderByChild("email")
      .equalTo(friendEmail)
      .once("value", snapshot => {
        const friendObject = snapshot.val();

        if (friendObject) {
          // TODO: how to map a single object
          const singleFriendList = Object.keys(friendObject).map(key => ({
            ...friendObject[key],
            uid: key
          }));

          // TODO: check if already exists in friend requests

          // User sending the request
          this.props.firebase
            .friend(authUser.friendsId)
            // TODO: be careful to update, not push, when adding to a list
            .child(singleFriendList[0].projectsId)
            .set({ status: "pending", email: singleFriendList[0].email });

          // User receiving the request
          this.props.firebase
            .friend(singleFriendList[0].friendsId)
            .child(authUser.projectsId)
            .set({ status: "received", email: authUser.email });
        } else {
          // TODO: display user does not exist to user
          console.log("user does not exist");
        }
      });

    this.setState({ friendEmail: "" });

    event.preventDefault();
  };

  onChangeFriendEmail = event => {
    this.setState({ friendEmail: event.target.value });
  };

  // TODO: implement on listener (?)
  onAcceptFriendRequest = (event, authUser, friend) => {
    this.props.firebase
      .users()
      .orderByChild("email")
      .equalTo(friend.email)
      .once("value", snapshot => {
        const friendObject = snapshot.val();

        if (friendObject) {
          // TODO: how to map a single object
          const singleFriendList = Object.keys(friendObject).map(key => ({
            ...friendObject[key],
            uid: key
          }));

          // TODO: check if already exists in friend requests

          // User accepting the request
          this.props.firebase
            .friend(authUser.friendsId)
            // TODO: be careful to update, not push, when adding to a list
            .child(singleFriendList[0].projectsId)
            .update({
              status: "accepted"
            });

          // User who sent the request
          this.props.firebase
            .friend(singleFriendList[0].friendsId)
            .child(authUser.projectsId)
            .update({ status: "accepted" });
        } else {
          // TODO: display user does not exist to user
          console.log("user does not exist");
        }
      });

    event.preventDefault();
  };

  // TODO: implement off listener
  onCancelFriendRequest = (event, authUser, friend) => {
    this.props.firebase
      .users()
      .orderByChild("email")
      .equalTo(friend.email)
      .once("value", snapshot => {
        const friendObject = snapshot.val();

        if (friendObject) {
          // TODO: how to map a single object
          const singleFriendList = Object.keys(friendObject).map(key => ({
            ...friendObject[key],
            uid: key
          }));

          // TODO: check if already exists in friend requests

          // User accepting the request
          this.props.firebase
            .friend(authUser.friendsId)
            // TODO: be careful to update, not push, when adding to a list
            .child(singleFriendList[0].projectsId)
            .remove();

          // User who sent the request
          this.props.firebase
            .friend(singleFriendList[0].friendsId)
            .child(authUser.projectsId)
            .remove();
        } else {
          // TODO: display user does not exist to user
          console.log("user does not exist");
        }
      });

    event.preventDefault();
  };

  render() {
    const { authUser } = this.props;
    const {
      name,
      userProjects,
      friendEmail,
      friendsAccepted,
      friendsPending,
      friendsReceived
      // isLoading
    } = this.state;

    return (
      <div>
        {/* TODO: add loading bars */}
        {/* {isLoading && <div>Loading...</div>} */}

        {/* TODO: check for null or empty projects */}
        {userProjects ? (
          <Card>
            <Card.Header>
              <Card.Header.Title>Your Projects</Card.Header.Title>
            </Card.Header>
            <ProjectList
              projectsId={authUser.projectsId}
              projects={userProjects}
              onRemoveProject={this.onRemoveProject}
              onEditProject={this.onEditProject}
              onUpdateHealth={this.onUpdateHealth}
            />

            {/* TODO: turn into component */}
            {/* Create new project */}
            <Card.Content style={{ paddingTop: 0 }}>
              <Level>
                <Level.Side align="left">
                  <Level.Item>
                    <form
                      onSubmit={event => this.onCreateProject(event, authUser)}
                    >
                      <Form.Field kind="addons">
                        <Form.Control>
                          <Form.Input
                            type="text"
                            value={name}
                            onChange={this.onChangeProjectName}
                            placeholder="Project Name"
                          />
                        </Form.Control>
                        <Form.Control>
                          <Button
                            type="submit"
                            onClick={event =>
                              this.onCreateProject(event, authUser)
                            }
                          >
                            Create Project
                          </Button>
                        </Form.Control>
                      </Form.Field>
                    </form>
                  </Level.Item>

                  <Level.Item>
                    <form
                      onSubmit={event =>
                        this.onAddFriendRequest(event, authUser, friendEmail)
                      }
                    >
                      <Form.Field kind="addons">
                        <Form.Control>
                          <Form.Input
                            type="text"
                            value={friendEmail}
                            onChange={this.onChangeFriendEmail}
                            placeholder="Friend's Email"
                          />
                        </Form.Control>
                        <Form.Control>
                          <Button type="submit">Add Friend</Button>
                        </Form.Control>
                      </Form.Field>
                    </form>
                  </Level.Item>
                </Level.Side>
              </Level>
            </Card.Content>

            {/* TODO: turn into component */}
            {/* TODO: check for empty or null friends. THIS CHECK ALREADY HAPPENS ON MOUNT */}
            {/* TODO: gets called twice? */}
            {friendsReceived && friendsReceived.length > 0 && (
              <Card.Content>
                <ReceivedFriendsList
                  authUser={authUser}
                  friendsReceived={friendsReceived}
                  onAcceptFriendRequest={this.onAcceptFriendRequest}
                  onCancelFriendRequest={this.onCancelFriendRequest}
                />
              </Card.Content>
            )}

            {friendsPending && friendsPending.length > 0 && (
              <Card.Content>
                <PendingFriendsList
                  authUser={authUser}
                  friendsPending={friendsPending}
                  onCancelFriendRequest={this.onCancelFriendRequest}
                />
              </Card.Content>
            )}
          </Card>
        ) : (
          <Card>
            <Card.Header>
              <Card.Header.Title>Your Projects</Card.Header.Title>
            </Card.Header>

            <Card.Content>
              <Level>
                <Level.Side align="left">
                  <Level.Item>
                    <p>You have no projects yet</p>
                  </Level.Item>
                </Level.Side>
              </Level>
            </Card.Content>

            {/* Add friend */}
            {/* TODO: turn into component */}
            {/* Create new project */}
            <Card.Content style={{ paddingTop: 0 }}>
              <Level>
                <Level.Side align="left">
                  <Level.Item>
                    <form
                      onSubmit={event => this.onCreateProject(event, authUser)}
                    >
                      <Form.Field kind="addons">
                        <Form.Control>
                          <Form.Input
                            type="text"
                            value={name}
                            onChange={this.onChangeProjectName}
                            placeholder="Project Name"
                          />
                        </Form.Control>
                        <Form.Control>
                          <Button
                            type="submit"
                            onClick={event =>
                              this.onCreateProject(event, authUser)
                            }
                          >
                            Create Project
                          </Button>
                        </Form.Control>
                      </Form.Field>
                    </form>
                  </Level.Item>

                  <Level.Item>
                    <form
                      onSubmit={event =>
                        this.onAddFriendRequest(event, authUser, friendEmail)
                      }
                    >
                      <Form.Field kind="addons">
                        <Form.Control>
                          <Form.Input
                            type="text"
                            value={friendEmail}
                            onChange={this.onChangeFriendEmail}
                            placeholder="Friend's Email"
                          />
                        </Form.Control>
                        <Form.Control>
                          <Button type="submit">Add Friend</Button>
                        </Form.Control>
                      </Form.Field>
                    </form>
                  </Level.Item>
                </Level.Side>
              </Level>
            </Card.Content>

            {/* TODO: turn into component */}
            {/* TODO: check for empty or null friends. THIS CHECK ALREADY HAPPENS ON MOUNT */}
            {/* TODO: gets called twice? */}
            {friendsReceived && friendsReceived.length > 0 && (
              <Card.Content>
                <ReceivedFriendsList
                  authUser={authUser}
                  friendsReceived={friendsReceived}
                  onAcceptFriendRequest={this.onAcceptFriendRequest}
                  onCancelFriendRequest={this.onCancelFriendRequest}
                />
              </Card.Content>
            )}

            {friendsPending && friendsPending.length > 0 && (
              <Card.Content>
                <PendingFriendsList
                  authUser={authUser}
                  friendsPending={friendsPending}
                  onCancelFriendRequest={this.onCancelFriendRequest}
                />
              </Card.Content>
            )}
          </Card>
        )}

        {friendsAccepted &&
          friendsAccepted.length > 0 &&
          friendsAccepted.map(friend => (
            <Card key={friend.uid}>
              <Card.Header>
                <Card.Header.Title>{friend.email}'s Projects</Card.Header.Title>
              </Card.Header>
              <AcceptedFriendProjectsList
                friend={friend}
                firebase={this.props.firebase}
                decayProjectHealth={this.decayProjectHealth}
              />
            </Card>
          ))}
      </div>
    );
  }
}

const ProjectList = ({
  projectsId,
  projects,
  onRemoveProject,
  onEditProject,
  onUpdateHealth
}) => (
  <Card.Content>
    <ul>
      {projects.map(project => (
        <ProjectItem
          key={project.uid}
          projectsId={projectsId}
          project={project}
          onRemoveProject={onRemoveProject}
          onEditProject={onEditProject}
          onUpdateHealth={onUpdateHealth}
        />
      ))}
    </ul>
  </Card.Content>
);

class ProjectItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editProject: this.props.project.name,
      health: this.props.project.health
    };
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editProject: this.props.project.name
    }));
  };

  onChangeEditProject = event => {
    this.setState({ editProject: event.target.value });
  };

  onSaveEditProject = () => {
    this.props.onEditProject(
      this.props.projectsId,
      this.props.project,
      this.state.editProject
    );

    this.setState({ editMode: false });
  };

  onSaveUpdateHealth = amount => {
    const updatedHealth = this.state.health + amount;

    if (updatedHealth >= 0 && updatedHealth <= 100) {
      this.props.onUpdateHealth(
        this.props.projectsId,
        this.props.project,
        this.state.health,
        amount
      );
      this.setState({ health: this.state.health + amount });
    }
  };

  onSaveRemoveProject = () => {
    this.props.onRemoveProject(this.props.projectsId, this.props.project.uid);
  };

  render() {
    const { project } = this.props;
    const { editMode, editProject } = this.state;

    return (
      <li style={{ marginBottom: "2em" }}>
        {editMode ? (
          <div>
            <Level>
              <Level.Side align="left">
                {/* TODO: turn center into className="has-text-centered" */}
                <Level.Item style={{ textAlign: "center" }}>
                  <Form.Input
                    type="text"
                    value={editProject}
                    onChange={this.onChangeEditProject}
                  />
                </Level.Item>
              </Level.Side>

              <Level.Side align="right">
                <Level.Item>
                  {/* TODO: show error if project name is too long */}
                  {/* TODO: when you hit enter, saves the project instead of having to click on "Save" */}
                  <Button color="primary" onClick={this.onSaveEditProject}>
                    Save
                  </Button>
                  <Button
                    color="danger"
                    type="button"
                    onClick={() => this.onSaveRemoveProject()}
                  >
                    Delete
                  </Button>
                  <Button onClick={this.onToggleEditMode}>Cancel</Button>
                </Level.Item>
              </Level.Side>
            </Level>

            {/* TODO: color inbetween 30 and 50 */}
            <ProjectProgressBar health={project.health} />
          </div>
        ) : (
          <div>
            <Level>
              <Level.Side align="left">
                <Level.Item style={{ textAlign: "center" }}>
                  {project.name}
                </Level.Item>
              </Level.Side>

              <Level.Side align="right">
                <Level.Item>
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => this.onSaveUpdateHealth(5)}
                    style={{ width: "2.5em", marginRight: "1em" }}
                  >
                    <span style={{ fontSize: "2em", marginTop: "-.475em" }}>
                      +
                    </span>
                  </Button>
                  <Button
                    type="button"
                    color="danger"
                    onClick={() => this.onSaveUpdateHealth(-5)}
                    style={{ width: "2.5em", marginRight: "1em" }}
                  >
                    <span style={{ fontSize: "2em", marginTop: "-.475em" }}>
                      -
                    </span>
                  </Button>
                  <Button onClick={this.onToggleEditMode}>Edit</Button>
                </Level.Item>
              </Level.Side>
            </Level>

            {/* TODO: color inbetween 30 and 50 */}
            <ProjectProgressBar health={project.health} />
          </div>
        )}
      </li>
    );
  }
}

const ProjectProgressBar = ({ health }) => {
  if (health > 30) {
    return (
      <Progress
        max={100}
        value={health}
        color="primary"
        size="small"
      ></Progress>
    );
  } else {
    return (
      <Progress max={100} value={health} color="danger" size="small"></Progress>
    );
  }
};

class AcceptedFriendProjectsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: []
    };
  }

  // TODO: put a isLoading in here
  componentDidMount() {
    this.props.firebase.project(this.props.friend.uid).on("value", snapshot => {
      const projectObject = snapshot.val();

      if (projectObject) {
        const friendProjects = Object.keys(projectObject).map(key => ({
          ...projectObject[key],
          uid: key
        }));

        this.setState({ projects: friendProjects });
      } else {
        this.setState({ projects: null });
      }
    });
  }

  componentWillUnmount() {
    this.props.firebase.project(this.props.friend.uid).off();
  }

  render() {
    const { friend, decayProjectHealth } = this.props;
    const { projects } = this.state;

    return (
      <div>
        <Card.Content>
          {projects ? (
            <ul>
              {projects.map(project => (
                <FriendProjectItem
                  key={project.uid}
                  project={project}
                  friendUid={friend.uid}
                  decayProjectHealth={decayProjectHealth}
                />
              ))}
            </ul>
          ) : (
            <span>They have no projects</span>
          )}
        </Card.Content>
      </div>
    );
  }
}

class FriendProjectItem extends Component {
  componentDidMount() {
    this.props.decayProjectHealth(this.props.friendUid, this.props.project.uid);
  }

  render() {
    const { project } = this.props;

    return (
      <li style={{ marginBottom: "2em" }}>
        <Level>
          <Level.Item>{project.name}</Level.Item>
        </Level>

        <ProjectProgressBar health={project.health} />
      </li>
    );
  }
}

// TODO: should authUser be used here, or just back in main?
// TODO: make ReceivedFriendsList and PendingFriendsList the same component
const ReceivedFriendsList = ({
  authUser,
  friendsReceived,
  onAcceptFriendRequest,
  onCancelFriendRequest
}) => (
  <div>
    <Level>
      <Level.Side align="left">
        <Level.Item>
          <p>Received Friend Requests:</p>
        </Level.Item>
      </Level.Side>
    </Level>
    <ul>
      {friendsReceived.map(friend => (
        <ReceivedFriendItem
          key={friend.uid}
          friend={friend}
          authUser={authUser}
          onAcceptFriendRequest={onAcceptFriendRequest}
          onCancelFriendRequest={onCancelFriendRequest}
        />
      ))}
    </ul>
  </div>
);

const ReceivedFriendItem = ({
  friend,
  authUser,
  onAcceptFriendRequest,
  onCancelFriendRequest
}) => (
  <li>
    <Level>
      <Level.Side align="left">
        <Level.Item>
          <p style={{ marginRight: "1em" }}>{friend.email}</p>
          <Button
            type="button"
            color="success"
            style={{ marginRight: "1em" }}
            onClick={event => onAcceptFriendRequest(event, authUser, friend)}
          >
            Accept
          </Button>
          <Button
            type="button"
            color="danger"
            onClick={event => onCancelFriendRequest(event, authUser, friend)}
          >
            Deny
          </Button>
        </Level.Item>
      </Level.Side>
    </Level>
  </li>
);

// TODO: should authUser be used here, or just back in main?
const PendingFriendsList = ({
  authUser,
  friendsPending,
  onCancelFriendRequest
}) => (
  <div>
    <Level>
      <Level.Side align="left">
        <Level.Item>
          <p>Pending Friend Requests:</p>
        </Level.Item>
      </Level.Side>
    </Level>
    <ul>
      {friendsPending.map(friend => (
        <PendingFriendItem
          key={friend.uid}
          friend={friend}
          authUser={authUser}
          onCancelFriendRequest={event =>
            onCancelFriendRequest(event, authUser, friend)
          }
        />
      ))}
    </ul>
  </div>
);

const PendingFriendItem = ({ friend, authUser, onCancelFriendRequest }) => (
  <li>
    <Level>
      <Level.Side align="left">
        <Level.Item>
          <p style={{ marginRight: "1em" }}>{friend.email}</p>
          <Button type="button" color="danger" onClick={onCancelFriendRequest}>
            Cancel
          </Button>
        </Level.Item>
      </Level.Side>
    </Level>
  </li>
);

// TODO: figure out how to do this efficiently
const ProjectsWithBase = withFirebase(ProjectsBase);

const Projects = () => (
  <AuthUserContext.Consumer>
    {authUser => <ProjectsWithBase authUser={authUser} />}
  </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default compose(
  //withEmailVerification,
  withAuthorization(condition)
)(ProjectsPage);
