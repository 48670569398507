import React, { useState } from "react";
import { Link } from "react-router-dom";

import * as ROUTES from "../../constants/routes";
import { AuthUserContext } from "../Session";
import * as ROLES from "../../constants/roles";

import "react-bulma-components/dist/react-bulma-components.min.css";
import { Container, Navbar } from "react-bulma-components";

import logo from "../images/tibble-logo.png";

const Navigation = () => (
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? (
          <NavigationAuth authUser={authUser} />
        ) : (
          <NavigationNonAuth />
        )
      }
    </AuthUserContext.Consumer>
  </div>
);

// TODO: add hamburger menu "active"
const NavigationAuth = ({ authUser }) => {
  const [active, setActive] = useState(false);

  return (
    <Navbar active={active}>
      <Container>
        <Navbar.Brand>
          <Navbar.Item to={ROUTES.LANDING} renderAs={Link}>
            <img src={logo} alt="tibble logo"></img>
          </Navbar.Item>
          <Navbar.Burger onClick={() => setActive(!active)} />
        </Navbar.Brand>
        <Navbar.Menu>
          <Navbar.Container position="end">
            <Navbar.Item
              to={ROUTES.PROJECTS}
              renderAs={Link}
              className="has-text-black-bis"
            >
              Projects
            </Navbar.Item>

            <Navbar.Item
              to={ROUTES.ACCOUNT}
              renderAs={Link}
              className="has-text-black-bis"
            >
              Account
            </Navbar.Item>

            {!!authUser.roles[ROLES.ADMIN] && (
              <Navbar.Item
                to={ROUTES.ADMIN}
                renderAs={Link}
                className="has-text-black-bis"
              >
                Admin
              </Navbar.Item>
            )}
          </Navbar.Container>
        </Navbar.Menu>
      </Container>
    </Navbar>
  );
};

const NavigationNonAuth = () => {
  const [active, setActive] = useState(false);

  return (
    <Navbar active={active}>
      <Container>
        <Navbar.Brand>
          <Navbar.Item to={ROUTES.LANDING} renderAs={Link}>
            <img src={logo} alt="tibble logo"></img>
          </Navbar.Item>
          <Navbar.Burger onClick={() => setActive(!active)} />
        </Navbar.Brand>
        <Navbar.Menu>
          <Navbar.Container position="end">
            <Navbar.Item
              to={ROUTES.SIGN_IN}
              renderAs={Link}
              className="has-text-black-bis"
            >
              Sign In
            </Navbar.Item>
            <Navbar.Item
              to={ROUTES.SIGN_UP}
              renderAs={Link}
              className="has-text-black-bis"
            >
              Sign Up
            </Navbar.Item>
          </Navbar.Container>
        </Navbar.Menu>
      </Container>
    </Navbar>
  );
};

export default Navigation;
