import React from "react";

import { withFirebase } from "../Firebase";

import "react-bulma-components/dist/react-bulma-components.min.css";
import { Button } from "react-bulma-components";

const SignOutButton = ({ firebase }) => (
  <Button
    fullwidth={true}
    color="danger"
    type="button"
    onClick={firebase.doSignOut}
  >
    Sign Out
  </Button>
);

export default withFirebase(SignOutButton);
