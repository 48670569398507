import React, { Component } from "react";

import { withFirebase } from "../Firebase";

import "react-bulma-components/dist/react-bulma-components.min.css";
import { Button, Form, Message } from "react-bulma-components";

const INITIAL_STATE = {
  passwordOne: "",
  passwordTwo: "",
  error: null
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;

    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(this.setState({ ...INITIAL_STATE }))
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo, error } = this.state;
    const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

    return (
      <form style={{ marginBottom: "0.75em" }} onSubmit={this.onSubmit}>
        <Form.Field>
          <Form.Control>
            <Form.Input
              name="passwordOne"
              value={this.state.passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="New Password"
            />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Control>
            <Form.Input
              name="passwordTwo"
              value={this.state.passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm New Password"
            />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Control>
            <Button
              fullwidth={true}
              color="primary"
              disabled={isInvalid}
              type="submit"
            >
              Reset My Password
            </Button>
          </Form.Control>
        </Form.Field>

        {/* TODO: turn this into a component */}
        {error && (
          <Message color="danger">
            <Message.Header>Error</Message.Header>
            <Message.Body style={{ wordBreak: "break-word" }}>
              {error.message}
            </Message.Body>
          </Message>
        )}
      </form>
    );
  }
}

export default withFirebase(PasswordChangeForm);
