import React from "react";

import "react-bulma-components/dist/react-bulma-components.min.css";
import {
  Columns,
  Container,
  Content,
  Footer,
  Heading,
  Section
} from "react-bulma-components";

import example from "../images/landing-page-example-edit-with-comments.jpg";

const Landing = () => (
  <div>
    <Section style={{ backgroundColor: "#8844BB" }}>
      <Container>
        <Heading
          className="is-size-1-tablet is-size-3-mobile has-text-centered has-text-white-bis"
          style={{ marginBottom: 0 }}
        >
          Keep your friends
        </Heading>
        <Heading className="is-size-1-tablet is-size-3-mobile has-text-centered has-text-white-bis">
          accoun-tibble!
        </Heading>
      </Container>
    </Section>
    <Section>
      <Container>
        <Columns>
          <Columns.Column>
            <Content className="is-size-5-tablet is-size-6-mobile has-text-black-bis">
              <p>
                <span style={{ fontWeight: "500" }}>Tibble</span> helps you keep
                track of your friends' side projects (and helps them keep track
                of yours!). This makes sure you and your friends stay on top of
                your work.
              </p>
              <hr />
              <p>
                Putting your project into Tibble gives it a health bar, which
                you and your friends can see.
              </p>
              <hr />
              <p>
                Keep your projects healthy and{" "}
                <span
                  className="has-background-primary has-text-white-bis is-hidden-touch"
                  style={{
                    padding: ".02em .4em .3em",
                    borderRadius: "0.25em",
                    fontWeight: "500"
                  }}
                >
                  green
                </span>
                <span
                  className="has-background-primary has-text-white-bis is-hidden-desktop"
                  style={{
                    padding: ".12em .4em .27em",
                    borderRadius: "0.25em",
                    fontWeight: "500"
                  }}
                >
                  green
                </span>{" "}
                by updating them.
              </p>
              <hr />
              <p>
                Neglect a project for too long and its health will go into the{" "}
                <span
                  className="has-background-danger has-text-white-bis is-hidden-touch"
                  style={{
                    padding: ".02em .4em .1em",
                    borderRadius: "0.25em",
                    fontWeight: "500"
                  }}
                >
                  red
                </span>
                <span
                  className="has-background-danger has-text-white-bis is-hidden-desktop"
                  style={{
                    padding: ".08em .4em .1em",
                    borderRadius: "0.25em",
                    fontWeight: "500"
                  }}
                >
                  red
                </span>
                .
              </p>
              <hr className="is-hidden-tablet" />
            </Content>
          </Columns.Column>

          <Columns.Column>
            <img
              style={{ maxHeight: "450px" }}
              src={example}
              alt="example tibble page"
            ></img>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>

    <Footer style={{ paddingBottom: "3em" }}>
      <Container className="has-text-centered has-text-black-bis">
        An app by{" "}
        <a
          href="https://twitter.com/jarrad_obrien"
          target="_blank"
          // TODO: why is it recommended to put the following rel in?
          rel="noopener noreferrer"
          className="has-text-white-ter"
          style={{
            backgroundColor: "rgb(29, 161, 242)",
            padding: "0.2em 0.5em",
            color: "white",
            borderRadius: "0.25em"
          }}
        >
          @jarrad_obrien
        </a>
      </Container>
    </Footer>
  </div>
);

export default Landing;
